import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import { FaSignOutAlt } from 'react-icons/fa';
import { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Image,
  Link,
  Stack,
  useColorMode,
  useColorModeValue,
  Text,
  Menu,
  MenuItem,
  MenuList,
  MenuButton,
  Tooltip
} from "@chakra-ui/react";
import { useLocation } from 'react-router-dom'

export default function NavBarHeader() {
  const { colorMode, toggleColorMode } = useColorMode();
  const [email] = useState(localStorage.getItem("email"));
  const [name] = useState(localStorage.getItem("name"));
  const [profile] = useState(localStorage.getItem("profile"));
  const location = useLocation();
  const selectedButtonColor = useColorModeValue("gray.200", "var(--chakra-colors-whiteAlpha-200)");
  const grayColorScheme = useColorModeValue("gray.300", "gray.700");

  let userRoles = [];

  if (localStorage.getItem('profile_canEdit') === 'true') {
    userRoles.push('canEdit');
  }

  if (localStorage.getItem('profile_canValidate') === 'true') {
    userRoles.push('canValidate');
  }

  if (localStorage.getItem('profile_canManage') === 'true') {
    userRoles.push('canManage');
  }

  function logOut() {
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    localStorage.removeItem("name");
    localStorage.removeItem("profile");
    localStorage.removeItem("profile_canEdit");
    localStorage.removeItem("profile_canValidate");
    window.location.href = "/";
  }

  function redirect(target) {
    window.location.href = target;
  }

  return (
    <>
      <Box bg={useColorModeValue("gray.100", "gray.900")} px={12}>
        <Flex alignItems={"center"} justifyContent={"left"} flexWrap={"wrap"} pt={2}>
          <Flex justifyContent={{ base: "center", lg: "right" }}>
            <Link href="/">
              <Image
                maxW="100%"
                w={320}
                src={
                  colorMode === "light"
                    ? "/assets/logo-dark.png"
                    : "/assets/logo-light.png"
                }
              />
            </Link>
          </Flex>

          <Flex pb={2} justifyContent={{ base: "center", lg: "right" }} alignItems={"center"} flexGrow={1} flexWrap={"wrap"}>
            <Flex justifyContent={{ base: "center", lg: "right" }} alignItems={"center"} flexGrow={1}>
              <Text spacing={7} fontSize="sm">
                {name || email} - {profile}
              </Text>
            </Flex>
            <Flex paddingLeft={{ base: 0, lg: 5 }} alignItems={"center"} justifyContent={"right"}>
              <Stack direction={"row"} spacing={7} style={{ padding: 5 }}>
                <Button onClick={toggleColorMode}>
                  {colorMode === "light" ? <MoonIcon /> : <SunIcon />}
                </Button>
              </Stack>
              <Stack direction={"row"} spacing={7}>
                <Button onClick={logOut}>
                  <FaSignOutAlt />
                </Button>
              </Stack>
            </Flex>
          </Flex>
        </Flex>

        {(userRoles.length > 1 || userRoles.includes('canManage')) && (
          <Flex borderTopStyle="solid" borderTopWidth="1px" borderTopColor={grayColorScheme} alignItems={"left"} justifyContent={"left"} flexWrap={"wrap"} py="8px" ml="-10px">
            {userRoles.includes('canEdit') && (
              <Stack direction={"row"} justifyContent={"left"} >
                <Button bg={location.pathname === "/mensalidades" ? selectedButtonColor : "none"} px="10px" size="sm" py="22px" onClick={() => redirect("/#/mensalidades")}>
                  Mensalidades
                </Button>
              </Stack>
            )}
            {userRoles.includes('canValidate') && (
              <Stack direction={"row"} justifyContent={"center"} px={2}>
                <Button bg={location.pathname === "/validacao" ? selectedButtonColor : "none"} px="10px" size="sm" py="22px" onClick={() => redirect("/#/validacao")}>
                  Validação Financeira
                </Button>
              </Stack>
            )}
            {userRoles.includes('canManage') && (
              <Stack direction={"row"} justifyContent={"center"}>
                <Menu>
                  <Tooltip>
                    <MenuButton as={Button} bg={location.pathname === "/usuarios" || location.pathname === "/perfis" ? selectedButtonColor : "none"} px="10px" size="sm" py="22px">
                      <Flex align="center" justify="center" gap="2">
                        <Text>Gerenciar Acesso</Text>
                      </Flex>
                    </MenuButton>
                  </Tooltip>
                  <MenuList>
                    <MenuItem fontSize="sm" onClick={() => redirect("/#/usuarios")}>Usuários</MenuItem>
                    <MenuItem fontSize="sm" onClick={() => redirect("/#/perfis")}>Perfis</MenuItem>
                  </MenuList>
                </Menu>
              </Stack>
            )}
          </Flex>
        )}
      </Box>
    </>
  );
}
