import {
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Text,
  Tooltip,
  Box,
  useColorModeValue
} from "@chakra-ui/react";
import { FaCheckCircle, FaTimesCircle, FaClock, FaBan } from "react-icons/fa";

const StatusButton = ({ fee, onUpdateValidation, onOpenJustification, status }) => {
  const redColorScheme = useColorModeValue("#e53e3e", "#feb2b2");
  const invertedColorScheme = useColorModeValue("#eeeff0", "#1a202c");

  const getStatusProperties = (status) => {
    switch (status.status) {
      case 2:
        return { color: "yellow", icon: <FaBan />, text: "N/A", isDisabled: status.disabled };
      case 1:
        return { color: "green", icon: <FaCheckCircle />, text: "Aprovado", isDisabled: status.disabled };
      case 0:
        return { color: "red", icon: <FaTimesCircle />, text: "Recusado", isDisabled: status.disabled };
      default:
        return { color: "yellow", icon: <FaClock />, text: "Em análise", isDisabled: status.disabled };
    }
  };

  const { color, icon, text, isDisabled } = getStatusProperties(status);

  return (
    <Menu>
      <Tooltip
        hasArrow
        placement="top"
        bg={redColorScheme}
        color={invertedColorScheme}
        boxShadow="lg"
        borderRadius="lg"
        fontSize="md"
        p="3"
        label={
          <Box>
            <Text fontWeight="bold">Motivo:</Text>
            <Text>{fee.financ_obs || "Sem justificativa"}</Text>
          </Box>
        }
        isDisabled={fee.financ_status !== false}
      >
        <MenuButton as={Button} colorScheme={color} width="180px" size="sm" isDisabled={isDisabled}>
          <Flex align="center" justify="center" gap="2">
            {icon}
            <Text>{text}</Text>
          </Flex>
        </MenuButton>
      </Tooltip>

      <MenuList>
        <MenuItem
          icon={<FaClock color="orange" />}
          onClick={() => onUpdateValidation(fee, null)}
          fontSize="sm"
        >
          Em Análise
        </MenuItem>
        <MenuItem
          icon={<FaCheckCircle color="green" />}
          onClick={() => onUpdateValidation(fee, true)}
          fontSize="sm"
        >
          Aprovar
        </MenuItem>
        <MenuItem
          icon={<FaTimesCircle color="red" />}
          onClick={() => onOpenJustification(fee)}
          fontSize="sm"
        >
          Recusar
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default StatusButton;
