import {
  Box,
  Flex,
  Input,
  useToast,
  IconButton,
  Heading,
  Button,
  Text,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";

import {
  FaExclamationTriangle,
  FaCheckCircle,
  FaUpload,
  FaEye,
} from "react-icons/fa";

import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { useState, useEffect } from "react";
import { formatToBackendDate } from "utils";
import { allowedExtensions } from "./UploadModal";
import { pathOr, isEmpty } from "ramda";
import { toastError, toastInfo, toastSuccess } from "constants"
import { SearchIcon } from "@chakra-ui/icons";
import ResponsivePagination from "react-responsive-pagination";

import "styles/pagination.css";
import StatusLabel from "./StatusLabel"
import Fees from "services/Fees";
import EditModal from "./EditModal"
import UploadModal from "./UploadModal";
import DeleteDocumentModal from "../Common/DeleteDocumentModal";
import { isNumber } from "utils";

const FeesForm = ({ loadingHandler }) => {
  const toast = useToast();
  const tableheaderbgc = useColorModeValue("gray.100", "#171923");
  const redColorScheme = useColorModeValue("#e53e3e", "#feb2b2");
  const invertedColorScheme = useColorModeValue("#eeeff0", "#1a202c");

  const [showEditModal, setShowEditModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [monthlyFee, setMonthlyFee] = useState({});
  const [showTable, setShowTable] = useState(false);
  const [monthlyFees, setMonthlyFees] = useState({ total: 0, data: [] });
  const [searchQuery, setSearchQuery] = useState(null);
  const [studentInfo, setStudentInfo] = useState(null);
  const [selectedPage, setSelectedPage] = useState(1);
  const [file, setFile] = useState(null);
  const [selectedFee, setSelectedFee] = useState(null);
  const [isDeleteDocumentModalOpen, setIsDeleteDocumentModalOpen] = useState(false);
  const [deleteDocumentModalSource, setDeleteDocumentModalSource] = useState("");
  const [showStudentInfo, setShowStudentInfo] = useState(false);

  // Abre o modal de exclusão de documento
  const handleOpenDeleteDocumentModal = (source, fee) => {
    setSelectedFee(fee);
    setDeleteDocumentModalSource(source);
    setIsDeleteDocumentModalOpen(true);
  };

  const handleEdit = (fee) => {
    setMonthlyFee(fee);
    setShowEditModal(true);
  };

  const handlePageChange = async (page) => {
    setSelectedPage(page);
    search(page);
  }

  const handleSearch = async () => {
    if (searchQuery) {
      await search(selectedPage);
    } else {
      setShowTable(false);
      setStudentInfo(null);
    }
  };

  const search = async (_selectedPage) => {
    try {
      loadingHandler(true);
      let feeData;
      let showStudentInfo;

      if (searchQuery && !isEmpty(searchQuery)) {
        showStudentInfo = isNumber(searchQuery);
        feeData = showStudentInfo ?
          await Fees.getByRA(searchQuery, _selectedPage - 1) :
          await Fees.getByName(searchQuery, _selectedPage - 1);
      } else {
        feeData = await Fees.getAll(_selectedPage - 1);
        showStudentInfo = false;
      }

      setShowStudentInfo(showStudentInfo);

      if (!feeData || feeData?.total === 0) {
        setShowTable(false);
        setStudentInfo(null);
        toast(toastInfo.notFound);
      } else {
        setMonthlyFees(feeData);
        if (!showStudentInfo) {
          setStudentInfo(null);
        } else {
          setStudentInfo(`${feeData?.data[0]?.RA} - ${feeData?.data[0]?.NOME}`);
        }
        setShowTable(true);
      }
    } catch (error) {
      console.error("Erro ao buscar dados do aluno:", error);
      toast(toastError.notFound);
    }
    finally {
      loadingHandler(false);
    }
  }

  const handleUpload = (fee) => {
    setMonthlyFee(fee);
    setShowUploadModal(true);
  };

  const handleOnchangeEdit = (field, value) => {
    const formatValue = field === "VENCTO" ? formatToBackendDate(value) : value;

    setMonthlyFee({
      ...monthlyFee,
      [field]: formatValue,
    });
  };

  const handleGetFile = async (fileId) => {
    try {
      const fileUrl = await Fees.getFile(fileId);
      window.open(fileUrl.fileUrl, "_blank");
    } catch (error) {
      console.error(error);
    }
  };

  const handleConfirmEdit = async () => {
    try {
      let updatedFee = {
        VALOR: monthlyFee.VALOR,
        VENCTO: monthlyFee.VENCTO,
      };

      updatedFee = await Fees.updateData(monthlyFee.ID, updatedFee);

      setMonthlyFees({
        total: monthlyFees.total,
        data: pathOr([], ["data"], monthlyFees).map((item) =>
          item.ID === updatedFee.ID ? updatedFee : item
        ),
      });

      toast(toastSuccess.monthlyFeeUpdated);
    } catch (error) {
      console.error(
        "Erro ao atualizar mensalidade:",
        error.response?.data || error.message
      );
      toast(toastError.updateMonthlyFeeError(error));
    } finally {
      setShowEditModal(false);
      setMonthlyFee({});
    }
  };

  const handleToggleStatus = async (fee) => {
    if (!fee.SYS_STATUS && fee.upload_file_id) {
      handleOpenDeleteDocumentModal("toggleStatus", fee);
    } else {
      await toogleStatus(fee);
    }
  };

  const handleDeleteDoc = async (fee) => {
    handleOpenDeleteDocumentModal("deleteDocument", fee);
  };

  // Altera status da mensalidade
  const toogleStatus = async (fee) => {
    try {
      fee.SYS_STATUS = !fee.SYS_STATUS;
      fee.DISABLED = !fee.SYS_STATUS;

      let updatedFee = {
        SYS_STATUS: fee.SYS_STATUS,
        DISABLED: fee.DISABLED
      };

      updatedFee = await Fees.updateData(fee.ID, updatedFee);

      if (updatedFee) {
        setMonthlyFees({
          total: monthlyFees.total,
          data: pathOr([], ["data"], monthlyFees).map((item) =>
            item.ID === updatedFee.ID ? updatedFee : item
          ),
        });
      }
      toast(toastSuccess.updateFeeStatus(updatedFee));
    } catch (error) {
      toast(toastError.updateMonthlyFee(error));
      console.error(
        "Erro ao atualizar SYS_STATUS:",
        error.response?.data || error.message
      );
    }
  };

  // Exclui comprovante
  const deleteDocument = async (fee) => {
    try {
      let updatedFee = await Fees.updateData(fee.ID, { upload_file_id: null });

      if (updatedFee) {
        setMonthlyFees({
          total: monthlyFees.total,
          data: pathOr([], ["data"], monthlyFees).map((item) =>
            item.ID === updatedFee.ID ? updatedFee : item
          ),
        });
      }
      toast(toastSuccess.updateFeeStatus(updatedFee));
    } catch (error) {
      toast(toastError.updateMonthlyFee(error));
      console.error(
        "Erro ao atualizar SYS_STATUS:",
        error.response?.data || error.message
      );
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileExtension = `.${selectedFile.name.split(".").pop()}`;
      if (!allowedExtensions.includes(fileExtension.toLowerCase())) {
        toast(toastError.fileError);
        setFile(null);
        return;
      }
      setFile(selectedFile);
    }
  };

  const handleFileUpload = async (fee) => {
    if (!file) {
      toast(toastError.emptyFile);
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const responseUpload = await Fees.uploadFile(formData);
      if (responseUpload) {
        fee.upload_file_id = responseUpload.fileId;

        const feeData = { upload_file_id: responseUpload.fileId };
        await Fees.updateData(monthlyFee.ID, feeData);

        setMonthlyFees({
          total: monthlyFees.total,
          data: pathOr([], ["data"], monthlyFees).map((item) =>
            item.ID === fee.ID ? fee : item
          ),
        });

        toast(toastSuccess.upload(file.name));
      } else {
        toast(toastError.notFoundMonthlyFee);
        return;
      }
    } catch (error) {
      toast(toastError.upload(error));
    } finally {
      setMonthlyFee({});
      setFile(null);
      setShowUploadModal(false);
    }
  };

  // Permite excluir o documento
  const handleDeleteDocument = async () => {
    if (deleteDocumentModalSource === "toggleStatus") {
      await toogleStatus(selectedFee);
    }

    if (deleteDocumentModalSource === "deleteDocument") {
      await deleteDocument(selectedFee);
    }

    setIsDeleteDocumentModalOpen(false);
  };

  const validateLabelStatus = (fee) => {
    if (fee.updated_by !== null && fee.financ_status === null) {
      if (fee.SYS_STATUS === false) {
        return { fee, status: null };
      } else {
        return { fee, status: 2 };
      }
    }
    else if (fee.updated_by === null && fee.financ_status === null) {
      return { fee, status: 2 };
    }
    else {
      return { fee, status: (fee.financ_status ? 1 : 0) };
    }
  };

  const handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      event.target.blur();
    }
  }

  const paginationCalulate = () => {
    const totalItems = monthlyFees.total;
    const itemsPerPage = 10;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    return totalPages;
  };

  // Inicialização
  useEffect(() => {

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box>
      <Heading
        as="h1"
        size="lg"
        mb="6"
        textAlign="center"
        bg={redColorScheme}
        bgClip="text"
      >
        Mensalidades
      </Heading>

      <form autoComplete="on" onSubmit={(e) => { e.preventDefault(); handleSearch(); }}>
        <Flex mb="4" justify="center" px={{ base: 6, lg: 0 }}>
          <Flex justify="center" width={{ base: "100%", lg: "50%" }}>
            <Input
              name="ra"
              placeholder="Pesquisar por RA ou nome"
              mr="2"
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyUp={handleKeyUp}
            />
            <IconButton
              icon={<SearchIcon color={invertedColorScheme} />}
              bg={redColorScheme}
              type="submit"
            />
          </Flex>
        </Flex>
      </form>

      {showTable && (
        <>
          <Flex width={"100%"}>
            <Flex justify="left" pl="6" width={"50%"}>
              {showStudentInfo && (
                <Text align={"left"} alignSelf={"flex-end"} fontWeight="bold" fontSize="sm">
                  {studentInfo}
                </Text>
              )}
            </Flex>
            <Flex justify="right" pr="6" alignSelf={"flex-end"} width={"50%"}>
              <Text align={"right"} fontSize="sm">
                Total de registros: {pathOr(0, ["total"], monthlyFees)}
              </Text>
            </Flex>
          </Flex>
          <Flex gap="4" mt="4" justify="center" direction={"column"}>
            <TableContainer width={"100%"} overflowX="auto">
              <Table variant="simple">
                <Thead bg={tableheaderbgc}>
                  <Tr>
                    {
                      !showStudentInfo && (
                        <>
                          <Th>RA</Th>
                          <Th>Nome</Th>
                        </>
                      )
                    }
                    <Th>Tipo</Th>
                    <Th>Curso</Th>
                    <Th>Tipo Pagamento</Th>
                    <Th>Valor</Th>
                    <Th>Parcela</Th>
                    <Th>Vencimento</Th>
                    <Th textAlign="center">Ações</Th>
                    <Th textAlign="center">Valid. Financeira</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {monthlyFees.data.map((fee) => (
                    <Tr key={fee.ID}>
                      {
                        !showStudentInfo && (
                          <>
                            <Td>{fee.RA || "N/A"}</Td>
                            <Td>{fee.NOME || "N/A"}</Td>
                          </>
                        )
                      }
                      <Td>{fee.TIPO_DEB || "N/A"}</Td>
                      <Td>{fee.CURSO || "N/A"}</Td>
                      <Td>{fee.TIPO_PAG || "N/A"}</Td>
                      <Td>{fee.VALOR || "N/A"}</Td>
                      <Td>{fee.PARC || "N/A"}</Td>
                      <Td>{fee.VENCTO || "N/A"}</Td>
                      <Td>
                        <Flex justify="center">
                          <Button
                            size="sm"
                            mr={2}
                            width={"120px"}
                            onClick={() => handleEdit(fee)}
                            leftIcon={<EditIcon />}
                            colorScheme="yellow"
                            isDisabled={localStorage.getItem("profile_canEdit") !== "true"}
                          >
                            Editar
                          </Button>
                          {fee.upload_file_id && (
                            <>
                              <Button
                                width={"150px"}
                                size="sm"
                                colorScheme="blue"
                                onClick={() => handleGetFile(fee.upload_file_id)}
                                leftIcon={<FaEye />}
                                borderRightRadius={0}
                              >
                                Ver comprovante
                              </Button>
                              <Button
                                width={"20px"}
                                mr={2}
                                size="sm"
                                colorScheme="red"
                                borderLeftRadius={0}
                                onClick={() => handleDeleteDoc(fee)}
                              >
                                <DeleteIcon />
                              </Button>
                            </>
                          )}
                          {!fee.upload_file_id && (
                            <Button
                              width={"180px"}
                              size="sm"
                              mr={2}
                              colorScheme="blue"
                              onClick={() => handleUpload(fee)}
                              leftIcon={<FaUpload />}
                              isDisabled={localStorage.getItem("profile_canEdit") !== "true"}
                            >
                              Enviar comprovante
                            </Button>
                          )}
                          <Button
                            width={"120px"}
                            size="sm"
                            onClick={() => handleToggleStatus(fee)}
                            isDisabled={localStorage.getItem("profile_canEdit") !== "true"} // Bloqueia ou habilita com base no estado
                            leftIcon={
                              fee.SYS_STATUS && !fee.DISABLED ? (
                                <FaExclamationTriangle />
                              ) : (
                                <FaCheckCircle />
                              )
                            }
                            colorScheme={fee.SYS_STATUS ? "red" : "green"}
                          >
                            {fee.SYS_STATUS ? "Pendente" : "Pago"}
                          </Button>
                        </Flex>
                      </Td>
                      <Td>
                        <StatusLabel
                          fee={fee}
                          status={validateLabelStatus(fee)}
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Flex>
          {paginationCalulate() > 1 && (
            <Flex mt="8" mb="4" justify="center">
              <ResponsivePagination
                current={selectedPage}
                total={paginationCalulate()}
                onPageChange={handlePageChange}
                maxWidth={500}
              />
            </Flex>
          )}
        </>
      )}

      <EditModal
        showModal={showEditModal}
        onChange={handleOnchangeEdit}
        close={() => setShowEditModal(false)}
        save={handleConfirmEdit}
        data={monthlyFee}
        currentPage={selectedPage}
      />

      <UploadModal
        showModal={showUploadModal}
        close={() => {
          setShowUploadModal(false);
          setFile(null);
          setMonthlyFee({});
        }}
        onChange={handleFileChange}
        save={() => handleFileUpload(monthlyFee)}
        data={file}
      />

      <DeleteDocumentModal
        isOpen={isDeleteDocumentModalOpen}
        onClose={() => setIsDeleteDocumentModalOpen(false)}
        onConfirm={handleDeleteDocument}
        source={deleteDocumentModalSource}
      />
    </Box>
  );
}

export default FeesForm;