import React from 'react'
import {
  Button,
  Flex,
  Icon,
  Input,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { FaFileUpload } from "react-icons/fa";
import { pathOr } from 'ramda';

export const allowedExtensions = [
  ".csv",
  ".pdf",
  ".docx",
  ".xlsx",
  ".png",
  ".jpg",
  ".jpeg",
  ".txt",
];

function UploadModal({
  showModal,
  close,
  onChange,
  save,
  data,
}) {
  const fileName = pathOr(null, ['name'], data)
  return (
    <Modal
        isOpen={showModal}
        onClose={close}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Upload de arquivo</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize="md" color="gray.500" textAlign="center" mb={6}>
              Suporte para os formatos mais comuns: CSV, PDF, DOCX, XLSX, PNG,
              JPG, TXT.
            </Text>

            <Flex direction="column" align="center" gap={4}>
              <Flex direction="column" align="center" mb={4} width="100%">
                <Input
                  type="file"
                  accept={allowedExtensions.join(",")}
                  onChange={onChange}
                  variant="outline"
                  mb={2}
                  p={2}
                  borderColor="gray.300"
                  placeholder="Escolha um arquivo"
                />
              </Flex>

              {fileName && (
                <Text color="gray.600" fontSize="sm">
                  Arquivo selecionado: <strong>{fileName}</strong>
                </Text>
              )}
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button
              leftIcon={<Icon as={FaFileUpload} />}
              colorScheme="blue"
              onClick={save}
              width="100%"
              size={"sm"}
            >
              Enviar Arquivo
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
  );
}

export default UploadModal;