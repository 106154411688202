import axios from "axios";

class Users {
  // Configuração do cabeçalho para requisições 
  getDefaultHeader(token = null) {
    token = token || localStorage.getItem("token");
    if (!token) {
      throw new Error("Token não está definido.");
    }

    return {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
  }

  // Busca usuário pelo e-mail
  async getByEmail(email, token = null) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ELDORADO_API_URL}/user/${email}?getDisabled=false`,
        this.getDefaultHeader(token)
      );
      return response.data;
    } catch (error) {
      console.error(
        "Erro ao buscar usuário pelo e-mail:",
        error.response?.data || error.message
      );
      throw error;
    }
  }

  // Busca usuário pelo e-mail ou parte dele
  async findByEmail(email, pageIndex = 0, token = null) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ELDORADO_API_URL}/user/find/${email}?pageIndex=${pageIndex}&pageSize=10`,
        this.getDefaultHeader(token)
      );
      return response.data;
    } catch (error) {
      console.error(
        "Erro ao buscar usuário pelo e-mail:",
        error.response?.data || error.message
      );
      throw error;
    }
  }

  // Busca todos os usuários
  async getAll(pageIndex = 0) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ELDORADO_API_URL}/user?pageIndex=${pageIndex}&pageSize=10`,
        this.getDefaultHeader()
      );
      return response.data;
    } catch (error) {
      console.error(
        "Erro ao buscar usuários:",
        error.response?.data || error.message
      );
      throw error;
    }
  }

  // Atualiza usuário
  async updateData(email, updatedData) {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_ELDORADO_API_URL}/user/update/${email}`,
        updatedData,
        this.getDefaultHeader()
      );
      return response.data;
    } catch (error) {
      console.error(
        "Erro ao atualizar usuário:",
        error.response?.data || error.message
      );
      throw error;
    }
  }

  // Adiciona usuário
  async add(updatedData) {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ELDORADO_API_URL}/user/add`,
        updatedData,
        this.getDefaultHeader()
      );
      return response.data;
    } catch (error) {
      console.error(
        "Erro ao adicionar usuário:",
        error.response?.data || error.message
      );
      throw error;
    }
  }
}

const user = new Users();
export default user;
