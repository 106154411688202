import axios from "axios";

class Fees {
  // Configuração do cabeçalho para requisições
  getDefaultHeader() {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token não está definido.");
    }
    return {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
  }

  // Configuração do cabeçalho para requisições
  getUploadHeader() {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token não está definido.");
    }
    return {
      headers: {
        "Content-Type": "multipart/form-data",
        timeout: 60000,
        Authorization: `Bearer ${token}`,
      },
    };
  }

  // Busca todas as mensalidades
  async getAll(pageIndex = 0) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ELDORADO_API_URL}/fees?pageIndex=${pageIndex}&pageSize=10`,
        this.getDefaultHeader()
      );
      return response.data;
    } catch (error) {
      console.error(
        "Erro ao buscar mensalidade:",
        error.response?.data || error.message
      );
      throw error;
    }
  }

  // Busca mensalidade pelo RA
  async getByRA(ra, pageIndex = 0) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ELDORADO_API_URL}/fees?RA=${ra}&pageIndex=${pageIndex}&pageSize=10`,
        this.getDefaultHeader()
      );
      return response.data;
    } catch (error) {
      console.error(
        "Erro ao buscar mensalidade pelo RA:",
        error.response?.data || error.message
      );
      throw error;
    }
  }

  // Busca mensalidade pelo Nome do aluno
  async getByName(name, pageIndex = 0) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ELDORADO_API_URL}/fees/filter/${name}?pageIndex=${pageIndex}&pageSize=10`,
        this.getDefaultHeader()
      );
      return response.data;
    } catch (error) {
      console.error(
        "Erro ao buscar mensalidade pelo RA:",
        error.response?.data || error.message
      );
      throw error;
    }
  }

  // Busca mensalidades pendentes de análise financeira pelo Nome do aluno
  async getPendingByName(name, showValidated = true, pageIndex = 0) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ELDORADO_API_URL}/fees/pending/filter/${name}?showValidated=${showValidated}&pageIndex=${pageIndex}&pageSize=10`,
        this.getDefaultHeader()
      );
      return response.data;
    } catch (error) {
      console.error(
        "Erro ao buscar mensalidade pelo RA:",
        error.response?.data || error.message
      );
      throw error;
    }
  }

  // Busca mensalidades pendentes de análise financeira pelo RA do aluno
  async getPendingByRA(ra, showValidated = true, pageIndex = 0) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ELDORADO_API_URL}/fees/pending?RA=${ra}&showValidated=${showValidated}&pageIndex=${pageIndex}&pageSize=10`,
        this.getDefaultHeader()
      );
      return response.data;
    } catch (error) {
      console.error(
        "Erro ao buscar mensalidade pelo Nome:",
        error.response?.data || error.message
      );
      throw error;
    }
  }

  // Busca todas as mensalidades pendentes
  async getPending(showValidated = true, pageIndex = 0) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ELDORADO_API_URL}/fees/pending?showValidated=${showValidated}&pageIndex=${pageIndex}&pageSize=10`,
        this.getDefaultHeader()
      );
      return response.data;
    } catch (error) {
      console.error(
        "Erro ao buscar mensalidade pelo Nome:",
        error.response?.data || error.message
      );
      throw error;
    }
  }

  // Atualiza mensalidade (SYS_STATUS, VALOR, VENCTO, UPLOAD_FILE_ID)
  async updateData(id, updatedData) {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_ELDORADO_API_URL}/fees/${id}`,
        updatedData,
        this.getDefaultHeader()
      );
      return response.data;
    } catch (error) {
      console.error(
        "Erro ao atualizar SYS_STATUS:",
        error.response?.data || error.message
      );
      throw error;
    }
  }

  // Busca arquivo do bucket, através do middleware
  async getFile(fileId) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ELDORADO_API_URL}/documents?fileId=${fileId}`,
        this.getDefaultHeader()
      );
      return response.data;
    } catch (error) {
      console.error(
        "Erro ao buscar arquivo:",
        error.response?.data || error.message
      );
      throw error;
    }
  }

  // Envia arquivo para o bucket, através do middleware
  async uploadFile(file) {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ELDORADO_API_URL}/upload`,
        file,
        this.getUploadHeader()
      );

      return response.data;
    } catch (error) {
      console.error(
        "Erro ao enviar arquivo:",
        error.response?.data || error.message
      );
      throw error;
    }
  }
}

const fees = new Fees();
export default fees;
