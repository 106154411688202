import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  Text,
} from "@chakra-ui/react";

const DisableProfileModal = ({ isOpen, onClose, onConfirm }) => {
  const handleConfirm = () => {
    onClose();
    onConfirm();
  }

  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            Desativar perfil
          </AlertDialogHeader>

          <AlertDialogBody>
            <Text>Ao desativar este perfil, todos os usuários associados a ele ficarão impossibilitados de acessar o sistema.</Text>
            <br/>Deseja prosseguir?
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button colorScheme='red' size={"sm"} onClick={handleConfirm}>
              Sim
            </Button>
            <Button onClick={onClose} size={"sm"} ml={3}>
              Cancelar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default DisableProfileModal;
