import {
    Box,
    Button,
    Flex,
    Input,
    useToast,
    IconButton,
    Heading,
    Text,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useColorModeValue,
} from "@chakra-ui/react";

import { EditIcon, DeleteIcon, RepeatIcon } from "@chakra-ui/icons";
import { SearchIcon, AddIcon } from "@chakra-ui/icons";
import { useState, useEffect } from "react";
import { pathOr, isEmpty, omit } from "ramda";
import { FaCheck, FaTimes } from "react-icons/fa";
import { toastError, toastInfo, toastSuccess } from "constants"
import ResponsivePagination from "react-responsive-pagination";

import Profiles from "services/Profiles";
import EditModal from "./EditModal";
import DisableProfileModal from "./DisableProfileModal";

const ProfileForm = ({ loadingHandler }) => {
    const toast = useToast();
    const redColorScheme = useColorModeValue("#e53e3e", "#feb2b2");
    const greenColorScheme = useColorModeValue("#38a169", "#9ae6b4");
    const tableheaderbgc = useColorModeValue("gray.100", "#171923");
    const invertedColorScheme = useColorModeValue("#eeeff0", "#1a202c");

    const [showTable, setShowTable] = useState(false);
    const [searchQuery, setSearchQuery] = useState(null);
    const [profiles, setProfiles] = useState({ total: 0, data: [] });
    const [selectedProfile, setSelectedProfile] = useState(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isDisableProfileModalOpen, setIsDisableProfileModalOpen] = useState(false);
    const [selectedPage, setSelectedPage] = useState(1);

    let currentPage = 1;

    const handleSearch = async () => {
        await search();
    };

    const search = async () => {
        try {
            loadingHandler(true);
            let profilesData;

            if (searchQuery && !isEmpty(searchQuery)) {
                profilesData = await Profiles.findByName(searchQuery, currentPage - 1);
            } else {
                profilesData = await Profiles.getAll(currentPage - 1);
            }

            if (!profilesData || profilesData?.total === 0) {
                setProfiles(profilesData);
                setShowTable(false);
                toast(toastInfo.profileNotFound);
            } else {
                setProfiles(profilesData);
                setShowTable(true);
            }
        } catch (error) {
            console.error("Erro ao buscar perfis:", error);
            toast(toastError.profileNotFound);
        }
        finally {
            loadingHandler(false);
        }
    }

    const handleOpenEditModal = (profile = null) => {
        setSelectedProfile(profile);
        setIsEditModalOpen(true);
    };

    const handleEnableDisableProfile = async (profile) => {
        setSelectedProfile(profile);

        if (profile.enabled) {
            setIsDisableProfileModalOpen(true);
        } else {
            enableDisableProfile(profile);
        }
    };

    const enableDisableProfile = async (profile) => {
        try {
            profile = profile || selectedProfile;
            let updatedProfile = omit(['isNew'], profile);
            updatedProfile.enabled = !updatedProfile.enabled;
            updatedProfile = await Profiles.updateData(profile.name, updatedProfile);

            setProfiles({
                total: profiles.total,
                data: pathOr([], ["data"], profiles).map((item) =>
                    item.name === updatedProfile.name ? updatedProfile : item
                ),
            });

            toast(toastSuccess.profileUpdated);
        } catch (error) {
            console.error(
                "Erro ao atualizar perfil:",
                error.response?.data || error.message
            );
            toast(toastError.updateProfileError(error));
        }
    };

    const handleSaveProfile = async (name, updatedProfile) => {
        const isNew = !name;
        try {
            const response = isNew ? await Profiles.add(updatedProfile) : await Profiles.updateData(name, updatedProfile);
            if (response) {
                if (isNew) {
                    updatedProfile.isNew = true;
                    profiles.data.unshift(updatedProfile);
                    profiles.total++;
                } else {
                    setProfiles({
                        total: profiles.total + (isNew ? 1 : 0),
                        data: pathOr([], ["data"], profiles).map((item) =>
                            item.name === name ? updatedProfile : item
                        ),
                    });
                }
                toast(isNew ? toastSuccess.profileAdded : toastSuccess.profileUpdated);
            }
        } catch (error) {
            console.error(
                isNew ? "Erro ao adicionar perfil:" : "Erro ao atualizar perfil:",
                error.response?.data || error.message
            );
            toast(isNew ? toastError.addProfileError(error) : toastError.updateProfileError(error));
        } finally {
            setSelectedProfile({});
        }
    };

    const handleKeyUp = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            event.target.blur();
        }
    };

    const paginationCalulate = () => {
        const totalItems = profiles.total;
        const itemsPerPage = 10;
        const totalPages = Math.ceil(totalItems / itemsPerPage);
        return totalPages;
    };

    const updateCurrentPage = async (page) => {
        setSelectedPage(page);
        currentPage = page;
        search();
    };

    // Inicialização
    useEffect(() => {
        search();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Box>
            <Heading
                as="h1"
                size="lg"
                mb="6"
                textAlign="center"
                bg={redColorScheme}
                bgClip="text"
            >
                Perfis de Usuário
            </Heading>

            <form autoComplete="on" onSubmit={(e) => { e.preventDefault(); handleSearch(); }}>
                <Flex mb="4" justify="center" px={{ base: 6, lg: 0 }}>
                    <Flex justify="center" width={{ base: "100%", lg: "50%" }}>
                        <Input
                            name="email"
                            placeholder="Pesquisar por nome do perfil"
                            mr="2"
                            onChange={(e) => setSearchQuery(e.target.value)}
                            onKeyUp={handleKeyUp}
                        />
                        <IconButton
                            icon={<SearchIcon color={invertedColorScheme} />}
                            bg={redColorScheme}
                            type="submit"
                            mr="2"
                        />
                        <IconButton
                            title="Adicionar Perfil"
                            icon={<AddIcon color={invertedColorScheme} />}
                            bg={redColorScheme}
                            type="button"
                            onClick={() => handleOpenEditModal()}
                        />
                    </Flex>
                </Flex>
            </form>

            {showTable && (
                <>
                    <Flex width={"100%"}>
                        <Flex justify="left" pl="6" width={"50%"}>

                        </Flex>
                        <Flex justify="right" pr="6" alignSelf={"flex-end"} width={"50%"}>
                            <Text align={"right"} fontSize="sm">
                                Total de registros: {pathOr(0, ["total"], profiles)}
                            </Text>
                        </Flex>
                    </Flex>
                    <Flex gap="4" mt="4" justify="center" direction={"column"}>
                        <TableContainer width={"100%"} overflowX="auto">
                            <Table variant="simple">
                                <Thead bg={tableheaderbgc}>
                                    <Tr>
                                        <Th>Nome</Th>
                                        <Th textAlign="center">Editar Mensalidades</Th>
                                        <Th textAlign="center">Validação Financeira</Th>
                                        <Th textAlign="center">Gerenciar Acesso</Th>
                                        <Th textAlign="center">Ativo</Th>
                                        <Th textAlign="center">Ações</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {profiles.data.map((profile) => (
                                        <Tr key={profile.name} className={profile.isNew ? "selected-row" : ""}>
                                            <Td>{profile.name || "N/A"}</Td>
                                            <Td><Flex justify="center">{profile.canEdit ? <FaCheck color={greenColorScheme} /> : <FaTimes color={redColorScheme} />}</Flex></Td>
                                            <Td><Flex justify="center">{profile.canValidate ? <FaCheck color={greenColorScheme} /> : <FaTimes color={redColorScheme} />}</Flex></Td>
                                            <Td><Flex justify="center">{profile.canManage ? <FaCheck color={greenColorScheme} /> : <FaTimes color={redColorScheme} />}</Flex></Td>
                                            <Td textAlign="center">{(profile.enabled ? 'SIM' : 'NÃO')}</Td>
                                            <Td>
                                                <Flex justify="center" gap="2">
                                                    <Button
                                                        size="sm"
                                                        w={120}
                                                        onClick={() => handleOpenEditModal(profile)}
                                                        leftIcon={<EditIcon />}
                                                        colorScheme="yellow"
                                                        isDisabled={localStorage.getItem("profile_canManage") !== "true"}
                                                    >
                                                        Editar
                                                    </Button>

                                                    <Button
                                                        size="sm"
                                                        w={120}
                                                        onClick={() => handleEnableDisableProfile(profile)}
                                                        leftIcon={profile.enabled ? <DeleteIcon /> : <RepeatIcon />}
                                                        colorScheme={profile.enabled ? "red" : "green"}
                                                        isDisabled={localStorage.getItem("profile_canManage") !== "true"}
                                                    >
                                                        {profile.enabled ? 'Desativar' : 'Reativar'}
                                                    </Button>
                                                </Flex>
                                            </Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </Flex>
                    {paginationCalulate() > 1 && (
                        <Flex mt="8" mb="4" justify="center">
                            <ResponsivePagination
                                current={selectedPage}
                                total={paginationCalulate()}
                                onPageChange={updateCurrentPage}
                                maxWidth={500}
                            />
                        </Flex>
                    )}
                </>
            )}
            <EditModal
                profile={selectedProfile}
                isOpen={isEditModalOpen}
                onClose={() => setIsEditModalOpen(false)}
                onSave={handleSaveProfile}
            />
            <DisableProfileModal
                isOpen={isDisableProfileModalOpen}
                onClose={() => setIsDisableProfileModalOpen(false)}
                onConfirm={enableDisableProfile}
            />
        </Box>
    )
};

export default ProfileForm;