import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormLabel,
  FormControl,
  Input
} from "@chakra-ui/react";
import { useState } from "react";

import SwitchButton from "../Common/SwitchButton";

const EditModal = ({ profile, isOpen, onClose, onSave }) => {
  const [name, setName] = useState("");
  let canEdit = profile?.canEdit || false;
  let canValidate = profile?.canValidate || false;
  let canManage = profile?.canManage || false;

  const handleSave = () => {
    const updatedProfile = {
      name: name || profile?.name || null,
      canEdit: canEdit == null ? profile?.canEdit : canEdit,
      canValidate: canValidate == null ? profile?.canValidate : canValidate,
      canManage: canManage == null ? profile?.canManage : canManage,
      enabled: profile?.enabled == null ? true : profile?.enabled
    };

    if (!updatedProfile.name) {
      return;
    }

    onSave(profile?.name || null, updatedProfile);
    handleClose();
  };

  const handleClose = () => {
    setName(null);
    canEdit = false;
    canValidate = false;
    canManage = false;
    onClose();
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={(e) => { e.preventDefault(); handleSave(); }}>
          <ModalHeader>{profile ? "Editar" : "Adicionar"} Perfil</ModalHeader>
          <ModalBody>
            <FormControl isRequired>
              <FormLabel>Nome</FormLabel>
              <Input
                placeholder="Informe o nome do perfil"
                defaultValue={profile?.name}
                onChange={(e) => setName(e.target.value)}
                required
                size="sm"
              />
            </FormControl>
            <FormControl mt={4} isRequired>
              <SwitchButton
                id="canEdit"
                isOn={profile?.canEdit}
                handleToggle={() => canEdit = !canEdit}
                label='Editar Mensalidades'
              />
            </FormControl>
            <FormControl mt={4} isRequired>
              <SwitchButton
                id="canValidate"
                isOn={profile?.canValidate}
                handleToggle={() => canValidate = !canValidate}
                label='Validação Financeira'
              />
            </FormControl>
            <FormControl mt={4} isRequired>
              <SwitchButton
                id="canManage"
                isOn={profile?.canManage}
                handleToggle={() => canManage = !canManage}
                label='Gerenciar Acesso'
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" colorScheme="red" mr={3} size={"sm"}>
              Salvar
            </Button>
            <Button onClick={handleClose} size={"sm"}>Cancelar</Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default EditModal;
