import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  Text,
} from "@chakra-ui/react";

const DeleteDocumentModal = ({ isOpen, onClose, onConfirm, source }) => {
  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            O comprovante será excluído
          </AlertDialogHeader>

          <AlertDialogBody>
            {source === 'toggleStatus' && (<><Text>Ao difinir mensalidade como 'Pendente', o comprovante será excluído.</Text><br /></>)}
            {source === 'updateValidation' && (<><Text>Ao validar como 'Recusado', o comprovante será excluído.</Text><br /></>)}
            Deseja prosseguir?
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button colorScheme='red' size={"sm"} onClick={onConfirm}>
              Sim
            </Button>
            <Button onClick={onClose} size={"sm"} ml={3}>
              Cancelar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default DeleteDocumentModal;
