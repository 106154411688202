import 'styles/main.css';
import Header from "components/Header";
import Footer from "components/Footer";
import FeesForm from "components/Forms/FeesForm"
import ValidationForm from "components/Forms/ValidationForm"
import UserForm from "components/Forms/UserForm"
import ProfileForm from "components/Forms/ProfileForm"
import { Box, Flex, Spacer, Spinner, Heading } from "@chakra-ui/react";
import { useLocation } from 'react-router-dom'
import { useState } from 'react';

export default function Main() {
  const location = useLocation();
  const [showLoading, setShowLoading] = useState(false);

  return (
    <>
      <Flex direction="column" minHeight="100vh">
        <Header />
        <Box flex="1" py="8" px={{ base: 0, lg: 12 }}>
          {location.pathname === "/mensalidades" && (<FeesForm loadingHandler={setShowLoading} />)}
          {location.pathname === "/validacao" && (<ValidationForm loadingHandler={setShowLoading} />)}
          {location.pathname === "/usuarios" && (<UserForm loadingHandler={setShowLoading} />)}
          {location.pathname === "/perfis" && (<ProfileForm loadingHandler={setShowLoading} />)}
        </Box>
        <Spacer />
        <Footer />
      </Flex>
      {showLoading && (<Flex
        justify="center"
        align="center"
        h='100%'
        w='100%'
        minH='100%'
        flexDirection="column"
        pos="fixed"
        inset="0"
        bg="var(--bg-color-alpha)"
      >
        <Spinner size="xl" color="var(--red-button)" speed="0.65s" thickness="4px" />
        <Heading as="h2" size="md" mt="4" color="var(--red-button)" textAlign="center">
          Carregando
        </Heading>
      </Flex>)}
    </>
  )
}