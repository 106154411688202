import {
    Box,
    Button,
    Flex,
    Input,
    useToast,
    IconButton,
    Heading,
    Text,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useColorModeValue,
} from "@chakra-ui/react";

import { EditIcon, DeleteIcon, RepeatIcon } from "@chakra-ui/icons";
import { useState, useEffect } from "react";
import { pathOr, isEmpty, omit } from "ramda";
import { SearchIcon, AddIcon } from "@chakra-ui/icons";
import ResponsivePagination from "react-responsive-pagination";
import EditModal from "./EditModal";
import DisableUserModal from "./DisableUserModal";

import Users from "services/Users";
import Profiles from "services/Profiles";
import { toastError, toastInfo, toastSuccess } from "constants"

const UserForm = ({ loadingHandler }) => {
    const toast = useToast();
    const redColorScheme = useColorModeValue("#e53e3e", "#feb2b2");
    const tableheaderbgc = useColorModeValue("gray.100", "#171923");
    const invertedColorScheme = useColorModeValue("#eeeff0", "#1a202c");

    const [users, setUsers] = useState({ total: 0, data: [] });
    const [selectedPage, setSelectedPage] = useState(1);
    const [showTable, setShowTable] = useState(false);
    const [searchQuery, setSearchQuery] = useState(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isDisableUserModalOpen, setIsDisableUserModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [profiles, setProfiles] = useState([]);

    let currentPage = 1;

    const handleSearch = async () => {
        await search();
    };

    const search = async () => {
        try {
            loadingHandler(true);
            let usersData;

            if (searchQuery && !isEmpty(searchQuery)) {
                usersData = await Users.findByEmail(searchQuery, currentPage - 1);
            } else {
                usersData = await Users.getAll(currentPage - 1);
            }

            if (!usersData || usersData?.total === 0) {
                setUsers(usersData);
                setShowTable(false);
                toast(toastInfo.userNotFound);
            } else {
                setUsers(usersData);
                setShowTable(true);
            }
        } catch (error) {
            console.error("Erro ao buscar usuários:", error);
            toast(toastError.userNotFound);
        }
        finally {
            loadingHandler(false);
        }
    }

    const updateCurrentPage = async (page) => {
        setSelectedPage(page);
        currentPage = page;
        search();
    }

    const handleKeyUp = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            event.target.blur();
        }
    }

    const paginationCalulate = () => {
        const totalItems = users.total;
        const itemsPerPage = 10;
        const totalPages = Math.ceil(totalItems / itemsPerPage);
        return totalPages;
    };

    const handleEnableDisableUser = async (user) => {
        try {
            setSelectedUser(user);
            if (!user.api_user_profiles.enabled) {
                setIsDisableUserModalOpen(true);
                return;
            }

            let updatedUser = omit(['api_user_profiles', 'isNew'], user);
            updatedUser.enabled = !updatedUser.enabled;
            updatedUser = await Users.updateData(user.email, updatedUser);

            setUsers({
                total: users.total,
                data: pathOr([], ["data"], users).map((item) =>
                    item.email === updatedUser.email ? updatedUser : item
                ),
            });

            toast(toastSuccess.userUpdated);
        } catch (error) {
            console.error(
                "Erro ao atualizar usuário:",
                error.response?.data || error.message
            );
            toast(toastError.updateUserError(error));
        }
    };

    const handleOpenEditModal = (user = null) => {
        setSelectedUser(user);
        setIsEditModalOpen(true);
    };

    const handleSaveUser = async (email, updatedUser) => {
        const isNew = !email;
        try {
            const response = isNew ? await Users.add(updatedUser) : await Users.updateData(email, updatedUser);
            if (response) {
                if (isNew) {
                    response.isNew = true;
                    users.data.unshift(response);
                    users.total++;
                } else {
                    setUsers({
                        total: users.total + (isNew ? 1 : 0),
                        data: pathOr([], ["data"], users).map((item) =>
                            item.email === email ? response : item
                        ),
                    });
                }
                toast(isNew ? toastSuccess.userAdded : toastSuccess.userUpdated);
            }
        } catch (error) {
            console.error(
                isNew ? "Erro ao adicionar usuário:" : "Erro ao atualizar usuário:",
                error.response?.data || error.message
            );
            toast(isNew ? toastError.addUserError(error) : toastError.updateUserError(error));
        } finally {
            setSelectedUser({});
        }
    };

    const loadProfiles = async () => {
        try {
            const profiles = await Profiles.getAll();

            if (profiles && profiles?.total > 0) {
                setProfiles(profiles.data);
            }
        } catch (error) {
            console.error("Erro ao buscar perfis:", error);
        }
    };

    // Inicialização
    useEffect(() => {
        loadProfiles();
        search();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Box>
            <Heading
                as="h1"
                size="lg"
                mb="6"
                textAlign="center"
                bg={redColorScheme}
                bgClip="text"
            >
                Usuários
            </Heading>

            <form autoComplete="on" onSubmit={(e) => { e.preventDefault(); handleSearch(); }}>
                <Flex mb="4" justify="center" px={{ base: 6, lg: 0 }}>
                    <Flex justify="center" width={{ base: "100%", lg: "50%" }}>
                        <Input
                            name="email"
                            placeholder="Pesquisar por e-mail"
                            mr="2"
                            onChange={(e) => setSearchQuery(e.target.value)}
                            onKeyUp={handleKeyUp}
                        />
                        <IconButton
                            icon={<SearchIcon color={invertedColorScheme} />}
                            bg={redColorScheme}
                            type="submit"
                            mr="2"
                        />
                        <IconButton
                            title="Adicionar Usuário"
                            icon={<AddIcon color={invertedColorScheme} />}
                            bg={redColorScheme}
                            type="button"
                            onClick={() => handleOpenEditModal()}
                        />
                    </Flex>
                </Flex>
            </form>

            {showTable && (
                <>
                    <Flex width={"100%"}>
                        <Flex justify="left" pl="6" width={"50%"}>

                        </Flex>
                        <Flex justify="right" pr="6" alignSelf={"flex-end"} width={"50%"}>
                            <Text align={"right"} fontSize="sm">
                                Total de registros: {pathOr(0, ["total"], users)}
                            </Text>
                        </Flex>
                    </Flex>
                    <Flex gap="4" mt="4" justify="center" direction={"column"}>
                        <TableContainer width={"100%"} overflowX="auto">
                            <Table variant="simple">
                                <Thead bg={tableheaderbgc}>
                                    <Tr>
                                        <Th>E-mail</Th>
                                        <Th>Perfil</Th>
                                        <Th>Ativo</Th>
                                        <Th textAlign="center">Ações</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {users.data.map((user) => (
                                        <Tr key={user.email} className={user.isNew ? "selected-row" : ""}>
                                            <Td>{user.email || "N/A"}</Td>
                                            <Td className={!user.api_user_profiles.enabled ? "line-through" : ""}>{user.profile || "N/A"}</Td>
                                            <Td>{(user.enabled && user.api_user_profiles.enabled ? 'SIM' : 'NÃO')}</Td>
                                            <Td>
                                                <Flex justify="center" gap="2">
                                                    <Button
                                                        size="sm"
                                                        w={120}
                                                        onClick={() => handleOpenEditModal(user)}
                                                        leftIcon={<EditIcon />}
                                                        colorScheme="yellow"
                                                        isDisabled={localStorage.getItem("profile_canManage") !== "true"}
                                                    >
                                                        Editar
                                                    </Button>

                                                    <Button
                                                        size="sm"
                                                        w={120}
                                                        onClick={() => handleEnableDisableUser(user)}
                                                        leftIcon={user.enabled && user.api_user_profiles.enabled ? <DeleteIcon /> : <RepeatIcon />}
                                                        colorScheme={user.enabled && user.api_user_profiles.enabled ? "red" : "green"}
                                                        isDisabled={localStorage.getItem("profile_canManage") !== "true"}
                                                    >
                                                        {user.enabled && user.api_user_profiles.enabled ? 'Desativar' : 'Reativar'}
                                                    </Button>

                                                </Flex>
                                            </Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </Flex>
                    {paginationCalulate() > 1 && (
                        <Flex mt="8" mb="4" justify="center">
                            <ResponsivePagination
                                current={selectedPage}
                                total={paginationCalulate()}
                                onPageChange={updateCurrentPage}
                                maxWidth={500}
                            />
                        </Flex>
                    )}
                </>
            )}
            <EditModal
                user={selectedUser}
                profiles={profiles}
                isOpen={isEditModalOpen}
                onClose={() => setIsEditModalOpen(false)}
                onSave={handleSaveUser}
            />
            <DisableUserModal
                user={selectedUser}
                isOpen={isDisableUserModalOpen}
                onClose={() => setIsDisableUserModalOpen(false)}
            />
        </Box>
    )
};

export default UserForm;