import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormLabel,
  FormControl,
  Input,
  Select
} from "@chakra-ui/react";
import { useState } from "react";

const EditModal = ({ user, profiles, isOpen, onClose, onSave }) => {
  const [email, setEmail] = useState("");
  const [profile, setProfile] = useState("");

  const handleSave = () => {
    const updatedUser = {
      email: email || user?.email || null,
      profile: profile || user?.profile || null,
      enabled: user?.enabled == null ? true : user?.enabled
    };

    if (!updatedUser.email) {
      return;
    }

    if (!updatedUser.profile) {
      return;
    }

    onSave(user?.email || null, updatedUser);
    handleClose();
  };

  const handleClose = () => {
    setEmail(null);
    setProfile(null);
    onClose();
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={(e) => { e.preventDefault(); handleSave(); }}>
          <ModalHeader>{user ? "Editar" : "Adicionar"} Usuário</ModalHeader>
          <ModalBody>
            <FormControl isRequired>
              <FormLabel>E-mail</FormLabel>
              <Input
                placeholder="Informe o e-mail"
                defaultValue={user?.email}
                onChange={(e) => setEmail(e.target.value)}
                required
                size="sm"
              />
            </FormControl>
            <FormControl mt={4} isRequired>
              <FormLabel>Perfil</FormLabel>
              <Select
                defaultValue={user?.profile || ""}
                className={(user?.profile == null && !profile) || (user?.api_user_profiles?.enabled === false && !profile) ? "chakra-select-empty" : "chakra-select"}
                onChange={(e) => setProfile(e.target.value)}
                required
                size="sm"
              >
                {!user && (<option key="" className="disabled-option" disabled value="">Selecione um perfil</option>)}
                {profiles.map(p =>
                  p.enabled || p.name === user?.profile ?
                    <option key={p.name} value={p.name} disabled={!p.enabled} className={!p.enabled ? "disabled-option" : ""}>{p.name}</option>
                    :
                    <></>
                )}
              </Select>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" colorScheme="red" mr={3} size={"sm"}>
              Salvar
            </Button>
            <Button onClick={handleClose} size={"sm"}>Cancelar</Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default EditModal;
