import { ChakraProvider } from "@chakra-ui/react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./views/Login";
import Main from "./views/Main";
import theme from "styles/theme";
import ProtectedRoute from "./ProtectedRoute";

export default function App() {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/mensalidades"
            element={
              <ProtectedRoute children={<Main />} role="canEdit" />
            }
          />
          <Route
            path="/validacao"
            element={
              <ProtectedRoute children={<Main />} role="canValidate" />
            }
          />
          <Route
            path="/usuarios"
            element={
              <ProtectedRoute children={<Main />} role="canManage" />
            }
          />
          <Route
            path="/perfis"
            element={
              <ProtectedRoute children={<Main />} role="canManage" />
            }
          />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}
